import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

export default styled.a<{ isDark: boolean }>((props) => ({
  backgroundColor: props.isDark ? props.theme.colors.dark : props.theme.colors.white,
  color: props.isDark ? props.theme.colors.white : props.theme.colors.dark,
  padding: '8px 26px',
  textDecoration: 'none',
  fontSize: 18,
  fontWeight: 400,
  borderRadius: 100,
  whiteSpace: 'nowrap',
  [BREAKPOINTS[800]]: {
    fontSize: 16,
    padding: '6px 16px',
  },
}));
