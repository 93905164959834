import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { BREAKPOINTS, INTERNAL_LINKS } from 'utils/constants';

interface INavigationLink {
  to: INTERNAL_LINKS;
  text: string;
}

interface IStyledLink {
  isDark: boolean;
}

const StyledLink = styled(Link)<IStyledLink>((props) => ({
  color: props.isDark ? props.theme.colors.dark : props.theme.colors.white,
  fontWeight: 400,
  fontSize: 16,
  textDecoration: 'none',
  marginRight: '36px',
  [BREAKPOINTS[955]]: {
    marginRight: 24,
  },
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
}));

export const NavigationLink: React.FC<INavigationLink & IStyledLink> = ({ text, to, ...rest }) => (
  <StyledLink to={to} {...rest}>
    {text}
  </StyledLink>
);

export default NavigationLink;
