import React from 'react';
import styled from '@emotion/styled';

import BurgerMenuButton from './BurgerMenuButton';
import NavLink from './NavLink';
import NavButton from './NavButton';

import { BREAKPOINTS, EXTERNAL_LINKS, INTERNAL_LINKS } from 'utils/constants';

interface INavigationLinks {
  onBurgerButtonClick: () => void;
  isDark: boolean;
}

const ForFreeText = styled.span({
  display: 'inline-block',
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
});

const NavigationLinks: React.FC<INavigationLinks> = ({ onBurgerButtonClick, ...rest }) => (
  <div>
    <NavLink text="About Us" to={INTERNAL_LINKS.ABOUT_US} {...rest} />
    <NavLink text="Blog" to={INTERNAL_LINKS.BLOG} {...rest} />
    <NavLink text="Contributors" to={INTERNAL_LINKS.CONTRIBUTORS} {...rest} />
    <NavButton href={EXTERNAL_LINKS.FUNNEL} {...rest}>
      Try Yours
      <ForFreeText>&nbsp;for Free</ForFreeText>
    </NavButton>
    <BurgerMenuButton onClick={onBurgerButtonClick} {...rest} />
  </div>
);

export default NavigationLinks;
