import React from 'react';
import styled from '@emotion/styled';

import BurgerIcon from 'assets/svgs/burger_menu.svg';
import BurgerIconDark from 'assets/svgs/burger_menu_dark.svg';
import { BREAKPOINTS } from 'utils/constants';

interface IBurgerMenuButton {
  onClick: () => void;
  isDark: boolean;
}

const Container = styled.div({
  display: 'none',
  marginLeft: 10,
  [BREAKPOINTS[800]]: {
    display: 'inline-block',
  },
});

const BurgerButton = styled.button({
  border: 'none',
  background: 'none',
  outline: 'none',
});

const BurgerMenuButton: React.FC<IBurgerMenuButton> = ({ onClick, isDark }) => (
  <Container>
    <BurgerButton onClick={onClick}>{!isDark ? <BurgerIcon /> : <BurgerIconDark />}</BurgerButton>
  </Container>
);

export default BurgerMenuButton;
