import { INTERNAL_LINKS } from './constants';

export const numberBetween = (number: number, from: number, to: number) =>
  number >= from && number <= to;

const contentParentLinks = [INTERNAL_LINKS.BLOG, INTERNAL_LINKS.NEWS, INTERNAL_LINKS.BUSINESS_BLOG];

export const isHeaderDark = (pathname: string) =>
  contentParentLinks.some((link) => {
    const matchIndex = pathname.indexOf(link);

    if (matchIndex === -1) return false;

    const linkSuffix = pathname.slice(matchIndex + link.length);
    const normalizedLinkSuffix = linkSuffix.replace('/', '');
    return !!normalizedLinkSuffix.length;
  });

export const isHeaderVisible = (pathname: string) =>
  !pathname.includes(INTERNAL_LINKS.EMAIL_UNSUBSCRIBE);
